<template>
  <SMIWrapper
    :isLoading="loading"
    :isError="error"
    @closeError="() => (error = false)"
  >
    <v-form @submit.prevent="createSmiLead" ref="form">
      <SmiHeader
        title="Enter your details to show your interest."
        description="We will not share this information with anyone and will only use it to
            contact you regarding your fibre journey."
      />
      <v-row class="mt-4">
        <v-col class="col-10 col-sm-6 pb-0">
          <v-text-field
            class="vuma-font-35"
            :rules="rules.firstName"
            label="Name *"
            color="#ff008e"
            required
            v-model.trim="form.firstName"
          />
        </v-col>
        <v-col class="col-10 col-sm-6 pb-0">
          <v-text-field
            class="vuma-font-35"
            :rules="rules.surname"
            label="Surname *"
            required
            color="#ff008e"
            v-model.trim="form.surname"
          />
        </v-col>
        <v-col class="col-10 col-sm-6 pb-0">
          <v-text-field
            class="vuma-font-35"
            :rules="rules.contactNumber"
            label="Mobile *"
            required
            color="#ff008e"
            v-model.trim="form.contactNumber"
          />
        </v-col>
        <v-col class="col-10 col-sm-6 pb-0">
          <v-text-field
            class="vuma-font-35"
            :rules="rules.email"
            label="Email *"
            required
            color="#ff008e"
            v-model.trim="form.email"
          />
        </v-col>
        <v-col class="col-12 col-sm-12 pb-0">
          <v-text-field
            class="vuma-font-35"
            :rules="rules.address"
            label="Address *"
            required
            multi-line
            color="#ff008e"
            v-model.trim="form.address"
          />
        </v-col>
        <v-col
          class="col-10 col-sm-6 pb-0"
          style="display: flex; align-items: center;"
        >
          <label style="font-size: 1.1rem;" class="vuma-font-25">
            What is your intent of purchase *
          </label>
        </v-col>
        <v-col class="col-10 col-sm-6 mt-5">
          <v-select
            :rules="rules.requiredOption"
            color="#ff008e"
            required
            :items="intentOptions"
            v-model="form.intent"
            class="py-0 my-0 vuma-font-35"
          />
        </v-col>
        <v-col class="col-12 mt-10">
          <v-checkbox v-model="latestUpdates" class="mt-0" color="#ff008e">
            <template v-slot:label>
              <div class="vuma-font-35">
                Get the latest build updates from Vumatel
              </div>
            </template>
          </v-checkbox>
          <v-checkbox v-model="becomeChampion" class="mt-0" color="#ff008e">
            <template v-slot:label>
              <div class="vuma-font-35">
                Become a VUMA area Champion
              </div>
            </template>
          </v-checkbox>
          <v-checkbox v-model="marketingInfo" class="mt-0" color="#ff008e">
            <template v-slot:label>
              <div class="vuma-font-35">
                Marketing Info Consent
                <v-tooltip top max-width="290px">
                  <template v-slot:activator="{ on }">
                    <a
                      class="vuma-font-35"
                      target="_blank"
                      href="https://cdn.vumatel.co.za/files/vumatel-privacy-policy.pdf?_ga=2.25878141.1753886684.1669116561-1225411227.1665489545"
                      @click.stop
                      v-on="on"
                    >
                      Privacy Policy
                    </a>
                  </template>
                  View Vumatel's privacy policy here.
                </v-tooltip>
              </div>
            </template>
          </v-checkbox>
          <v-checkbox
            v-model="consent"
            class="mt-0"
            color="#ff008e"
            required
            :rules="rules.terms"
          >
            <template v-slot:label>
              <div class="vuma-font-35">
                I consent to sharing my info with Vuma and their third parties
                (ISP and registered contractors)*
                <v-tooltip top max-width="290px">
                  <template v-slot:activator="{ on }">
                    <a
                      target="_blank"
                      href="https://cdn.vumatel.co.za/files/vumatel-paia-popi-manual.pdf"
                      @click.stop
                      v-on="on"
                    >
                      POPI Policy
                    </a>
                  </template>
                  View Vumatel's POPI policy here.
                </v-tooltip>
              </div>
            </template>
          </v-checkbox>
        </v-col>
        <v-col class="mt-4 mb-2">
          <v-btn rounded dark type="submit">
            I'm interested
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </SMIWrapper>
</template>

<script>
import SMIWrapper from './SMIWrapper.vue';
import postLead from './api';
import validators from './validators';
import SmiHeader from './SmiHeader.vue';

export default {
  name: 'ShowMyInterestTestSocialMedia',
  components: {
    SMIWrapper,
    SmiHeader,
  },
  data: () => ({
    latestUpdates: false,
    becomeChampion: false,
    marketingInfo: false,
    consent: false,
    form: {
      firstName: '',
      surname: '',
      contactNumber: '',
      email: '',
      address: '',
      intent: '',
    },
    intentOptions: ['Resident', 'School', 'Business'],
    leadSourceOptions: [
      'Shopfront',
      'Social Media',
      'Door2Door',
      'CallCenter',
      'Other',
      'Purchased List',
      'Digital Lead',
    ],
    showLeadSource: false,
    loading: false,
    error: false,
    rules: {
      firstName: [(v) => validators.firstName(v)],
      surname: [(v) => validators.surname(v)],
      contactNumber: [(v) => validators.mobile(v)],
      email: [(v) => validators.email(v)],
      terms: [(v) => !!v || 'Please accept terms and conditions'],
      address: [(v) => !!v || 'Please enter your address'],
      requiredOption: [(v) => !!v || 'Please select an option'],
    },
    salesforceIdentities: {
      submitUrl: process.env.VUE_APP_SMI_SUBMIT_URL,
      oid: process.env.VUE_APP_SMI_OID,
      contactTimeId: process.env.VUE_APP_SMI_CONTACT_TIME_ID,
      marketingId: process.env.VUE_APP_SMI_MARKETING_ID,
      thirdPartyConsentId: process.env.VUE_APP_SMI_THIRD_PARTY_ID,
      latestUpdates: process.env.VUE_APP_SMI_BUILD_UPDATES_ID,
      becomeChampion: process.env.VUE_APP_SMI_AREA_CHAMPION_ID,
      purchaseIntent: process.env.VUE_APP_SMI_PURCHASE_INTENT_ID,
    },
  }),
  mounted() {
    const userAddress = this.$cookies.get('user_address')
    if (userAddress) this.form.address = userAddress
  },
  methods: {
    async createSmiLead() {
      const formDetails = await this.$refs.form.validate();
      if (formDetails) {
        this.loading = true;
        try {
          const response = await postLead(
            this.salesforceIdentities.submitUrl,
            this.getLeadPayload()
          );
          if (!response) throw new Error('Failed to submit details');
          this.$router.replace('/thank-you');
        } catch (error) {
          this.error = true;
          console.error(error);
        } finally {
          this.loading = false;
        }
      }
    },
    getLeadPayload() {
      const payload = {
        oid: this.salesforceIdentities.oid,
        retURL: 'https://www.vumatel.co.za',
        first_name: this.form.firstName,
        last_name: this.form.surname,
        email: this.form.email,
        mobile: this.form.contactNumber,
        street: this.form.address,
        lead_source: 'Shopfront',
        company: 'Vuma Lead',
        submit: `I'm Interested`,
        [this.salesforceIdentities.purchaseIntent]: this.form.intent
      };

      this.latestUpdates &&
        Object.assign(payload, {
          [this.salesforceIdentities.latestUpdates]: 1,
        });
      this.becomeChampion &&
        Object.assign(payload, {
          [this.salesforceIdentities.becomeChampion]: 1,
        });
      this.consent &&
        Object.assign(payload, {
          [this.salesforceIdentities.thirdPartyConsentId]: 1,
        });
      this.marketingInfo &&
        Object.assign(payload, { [this.salesforceIdentities.marketingId]: 1 });
      return payload;
    },
  },
};
</script>

<style lang="scss">
label {
  text-align: left;
}
</style>
