<template>
  <div class="home">
    <HomeBanner />
    <div class="coverage-results">
      <h2 class="vuma-font-25">{{ coverageStatus }}</h2>
      <v-container id="vuma-interested" class="py-10">
        <slot></slot>
        <v-dialog v-model="isLoading" max-width="80" persistent>
          <v-card>
            <v-card-text
              class="text-center pink-primary"
              style="padding: 16px !important;"
            >
              <v-progress-circular :size="40" indeterminate color="#ff008e" />
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="isError" max-width="450" persistent>
          <v-card>
            <v-card-text style="text-align: center;">
              <v-icon style="font-size: 3rem;" color="#ff008e" class="pt-6">
                mdi-alert-circle-outline
              </v-icon>
            </v-card-text>
            <v-card-title class="vuma-font-35" style="text-align: center; justify-content: center;">
              Sorry! Your details were not submitted.
            </v-card-title>
            <v-card-text style="text-align: center; font-size: 1rem" class="vuma-font-25">
              Please try again later
            </v-card-text>
            <v-card-actions style="justify-content: center;" class="pa-4">
              <v-btn rounded dark type="button" @click="closeError">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </div>
  </div>
</template>

<script>
import HomeBanner from '@/components/Home/Banner';

export default {
  name: 'SMIWrapper',
  components: {
    HomeBanner,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    coverageStatus: 'Be a part of Vuma network',
  }),
  methods: {
    closeError() {
      this.$emit('closeError');
    },
  }
};
</script>

<style lang="scss">
#vuma-interested {
  max-width: 900px !important;
  width: 100%;
}

h3 {
  font-family: 'Core Sans R 35', 'Roboto', sans-serif !important;
}
</style>
