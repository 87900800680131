export default async (url, payload) => {
  const options = {
    method: 'POST',
    body: getEncodedPayloadString(payload),
    headers: new Headers({
      'content-type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }),
    mode: 'no-cors',
  };
  try {
    await fetch(url, options);
    return true
  } catch (error) {
    console.error(error);
  }
  return false;
};

const getEncodedPayloadString = (payload) => {
  const formBody = [];
  for (let property in payload) {
    if (Array.isArray(payload[property])) {
      let encodedKey = encodeURIComponent(property);
      payload[property].forEach(item => {
        let encodedValue = encodeURIComponent(item);
        formBody.push(encodedKey + '=' + encodedValue);
      })
    } else {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(payload[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
  }
  return formBody.join('&');
};
