const specialCharRegex = /^[a-zA-Z0-9' _\-,]+$/

const baseValidator = {
  specialCharacter: (value = '') => {
    return specialCharRegex.exec(value)
  },
};

export default {
  firstName: (value) => {
    if (!value) return 'Please enter your first name';
    if (!baseValidator.specialCharacter(value))
      return 'Please enter valid first name';
    return true;
  },
  surname: (value) => {
    if (!value) return 'Please enter your surname';
    if (!baseValidator.specialCharacter(value))
      return 'Please enter valid surname';
    return true;
  },
  email: (email) => {
    if (!email) return 'Please enter your email';
    if (!/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/.test(email))
      return 'Please enter valid email address';
    return true;
  },
  mobile: (value) => {
    if (!value) return 'Please enter your contact number';
    if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(value))
      return 'Please enter valid contact number';
    return true;
  },
  address: (value) => {
    if (!value) return 'Please enter your address';
    if (!baseValidator.specialCharacter(value))
      return 'Please enter valid address';
    return true;
  },
  intent: (value) => {
    return !!value || 'Please select your intent of purchase';
  },
  requiredField: (value, field) => {
    if (!value) return 'Please enter your ' + field;
    if (!baseValidator.specialCharacter(value))
      return 'Please enter valid ' + field;
    return true;
  },
  company: (value) => {
    if (!value) return 'Please enter your company name';
    if (!baseValidator.specialCharacter(value))
      return 'Please enter valid company name';
    return true;
  },
  city: (value) => {
    if (!value) return 'Please enter your city name';
    if (!baseValidator.specialCharacter(value))
      return 'Please enter valid city';
    return true;
  },
  state: (value) => {
    if (!value) return 'Please enter your state name';
    if (!baseValidator.specialCharacter(value))
      return 'Please enter valid state name';
    return true;
  },
};
